import { render, staticRenderFns } from "./DownloadByOs.vue?vue&type=template&id=2316340b&scoped=true"
import script from "./DownloadByOs.vue?vue&type=script&lang=js"
export * from "./DownloadByOs.vue?vue&type=script&lang=js"
import style0 from "./DownloadByOs.vue?vue&type=style&index=0&id=2316340b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2316340b",
  null
  
)

export default component.exports