
export default {
    name: 'DownloadOnStore',

    data() {
        return {
            menus: [
                {
                    image: '/timer.png',
                    text: 'convenienceAndAccessibility',
                },
                {
                    image: '/click.png',
                    text: 'enhancedUserExperience',
                },
                {
                    image: '/bell.png',
                    text: 'pushNotificationAndExclusiveOfficer',
                },
                {
                    image: '/defence.png',
                    text: 'higherSecurity',
                },
            ],
            downloadButtons: [
                {
                    icon: 'mdi-android',
                    text: 'Android',
                    path: '/download/android',
                },
                {
                    icon: 'mdi-apple',
                    text: 'IOS',
                    path: '/download/ios',
                },
            ],
        }
    },
    head() {
        return {
            title: `Download your application`,
            meta: [
                {
                    hid: 'og:title',
                    name: 'og:title',
                    content: `Download your application`, // This can be overridden by page-level meta tags.
                },
            ],
        }
    },

    methods: {
        naviagetTo(path) {
            this.$router.push(path)
        },
    },
}
