
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Navigation, Pagination } from 'swiper'
import downloadByOS from '~/mixins/pages/downloadByOS'
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation, Pagination])

export default {
    name: 'DownloadByOs',
    components: {
        Swiper,
        SwiperSlide,
    },

    mixins: [downloadByOS],

    props: {
        operatingSystem: {
            type: String,
            default: '',
        },
    },
}
